import zwitCore from "zw-core";
const BASE_URL = process.env.NODE_ENV === "development" ? "/apis" : "";

export default {
  // 统计阅读量
  readCount(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/bjzx/readCount",
      data,
      false
    );
  },
  // 学者设置图集封面
  setGalleryCover(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/setGalleryCover",
      data,
      false
    );
  },
  // 获取隐私状态
  getPrivacyList(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/privacy/nf/getPrivacyList",
      data,
      false
    );
  },
  // 研究领域
  getFieldRecommend() {
    return zwitCore.HTTP.json(
      BASE_URL + "/zw-knowledge/fieldRecommend/list",
      {},
      false
    );
  },
  // 学者列表
  getAutherList(data) {
    return zwitCore.HTTP.json(
      BASE_URL + "/zw-knowledge/userRecommend/page",
      data,
      false
    );
  },
  // 更多搜索学者列表
  getScholarList(data) {
    return zwitCore.HTTP.json(
      BASE_URL + "/zw-user/scholar/nf/scholarList",
      data,
      false
    );
  },
  // 学者头衔
  getHonourList() {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/honour/nf/getHonourList",
      {},
      false
    );
  },
  // 研究领域
  getOneList() {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/researchField/getOneList");
  },
  getTwoList(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-public/researchField/getTwoList",
      data
    );
  },
  // 学者成果列表
  getArticleById(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/bjzx/getArticleById",
      data,
      false
    );
  },
  getCount(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/bjzx/count",
      data,
      false
    );
  },
  getIfList(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/bjzx/getIfList",
      data,
      false
    );
  },
  getList(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/bjzx/getList",
      data,
      false
    );
  },
  // 简介数量
  introduceCount(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/bjzx/introduceCount",
      data,
      false
    );
  },
  // 研究领域
  getKeywords(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/bjzx/keywords",
      data,
      false
    );
  },
  getEcharts(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/bjzx/echarts",
      data,
      false
    );
  },
  // 学者详情
  spaceScholarInfo(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/spaceScholarInfo",
      data,
      false
    );
  },
  // 学者详情
  spaceScholarProfile(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/spaceScholarProfile",
      data,
      false
    );
  },
  // 用户认领学者
  claimScholar(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/claimScholar",
      data,
      false
    );
  },
  // 教育背景
  spaceScholarEduExp(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/spaceScholarEduExp",
      data,
      false
    );
  },
  // 工作经历
  spaceScholarWorkExp(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/spaceScholarWorkExp",
      data,
      false
    );
  },
  // 职称经历
  spaceScholarTitleExp(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/spaceScholarTitleExp",
      data,
      false
    );
  },
  // 社会任职
  spaceScholarMoonExp(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/spaceScholarMoonExp",
      data,
      false
    );
  },
  // 获奖情况
  spaceScholarPrize(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/spaceScholarPrize",
      data,
      false
    );
  },
  // 学者动态
  spaceScholarDynamic(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/spaceScholarDynamic",
      data,
      false
    );
  },
  // 学者名片
  downBusiness(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/downBusiness",
      data,
      false
    );
  },
  // 学者图集
  spaceScholarGallery(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/spaceScholarGallery",
      data,
      false
    );
  },
};
