import zwitCore from "zw-core";
import global from '../utils/global.js';
import axios from "axios";
const BASE_URL = process.env.NODE_ENV === "development" ? "/apis" : "";
const download = `${global.global.url1}/zw-public/web/common/downloadArticle2`;
const downloadNoToken = `${global.global.url1}/zw-public/web/common/downloadArticle3`;
const readUrl = `${global.global.url1}/zw-public/web/common/readPDFPicFile2`;
const readUrlNoToken = `${global.global.url1}/zw-public/web/common/readPDFPicFile3`;
export default {
  download,
  readUrl,
  downloadNoToken,
  readUrlNoToken,
  isCollect(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-information/articleCollect/isCollect",
      data
    );
  },
  CollectItem(data) {
    return zwitCore.HTTP.json(
      BASE_URL + "/zw-information/articleCollect/addOrDelCollect",
      data
    );
  },
  downPdf(data) {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/web/common/download", data);
  },

  // 搜索新版es重构
  async searchArticle(data) {
    //   return zwitCore.HTTP.postJsonData(
    //     BASE_URL + "/zw-elasticsearch/search/article",
    //       data,
    //       false
    //   );
    return await axios({
        url: `${BASE_URL}/zw-elasticsearch/search/article`,
        method: "post",
        data,
        headers: {
          rsa: "no",
        },
    });
  },
  getIncludeList() {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/include/includeList");
  },
  getYearList(data) {
    return zwitCore.HTTP.get(BASE_URL + "/zw-public/year/infoPage", data);
  },
  async getTypeCount(data) {
    // return zwitCore.HTTP.postJsonData(
    //     BASE_URL + "/zw-information/bjzx/getTypeCount",
    //   data,
    //   false
    // );
    return await axios({
        url: `${BASE_URL}/zw-information/bjzx/getTypeCount`,
        method: "post",
        data,
        headers: {
          rsa: "no",
        },
    });
  },
  async getYearCount(data) {
    // return zwitCore.HTTP.postJsonData(
    //     BASE_URL + "/zw-information/bjzx/getYearCount",
    //   data,
    //   false
    // );
    return await axios({
        url: `${BASE_URL}/zw-information/bjzx/getYearCount`,
        method: "post",
        data,
        headers: {
          rsa: "no",
        },
    });
  },
  async getDataStyleCount(data) {
    // return zwitCore.HTTP.postJsonData(
    //     BASE_URL + "/zw-information/bjzx/getDataStyleCount",
    //   data,
    //   false
    // );
    return await axios({
        url: `${BASE_URL}/zw-information/bjzx/getDataStyleCount`,
        method: "post",
        data,
        headers: {
          rsa: "no",
        },
    });
  },
  searchScholarList(data) {
    return zwitCore.HTTP.get(
      BASE_URL + "/zw-user/scholar/nf/searchScholar",
      data,
      false
    );
  },
};
